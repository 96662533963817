


/* :::::::::::::::::::::::::::::

  CSS VIEW PALESTRA DO EVENTO 

  ::::::::::::::::::::::::::;

/* PALESTRA DO EVENTO  ------------------------------------------------------------------------------------------------------------------------------------------------
------------------------- */



#network-evento  {
    background-color: #000;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}



.container-network{
    background-repeat: no-repeat;
    background-position: center;
    .turn-back {
        position: absolute;
        bottom: 24px;
        left: 0;
        width: 150px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        .fa {
            transition: transform .4s ease;
            position: absolute;
            height: 120px;
        }
        p {
            position: absolute;
            top: 0;
            opacity: 0;
            transition: opacity .4s ease;
            color: #FFF;
            font-weight: bold;
        }
        &:hover {
            p {
                opacity: 1
            }
            .fa {
                transform: translateY(16px);
            }
        }
    }
}


#network-evento .container-network {
    background-color: #000;
    background-repeat: no-repeat;
    background-size: 100% auto;
   
    position: relative;
    
    margin: 0 auto;
    .go-back {
        position: absolute;
        top: 8px;
        left: 8px;
        padding: 8px;
        color: #FFF;
        opacity: 0.8;
        z-index: 1000;
        &:hover {
            opacity: 1;
        }
        .fab-brands {
            margin-right: 8px;
        }
    }
    .container {
        background: rgba(74,74,74,0.9);
        border-radius: 5px;
        max-height: 80vh;
        overflow: auto;
    }
    .network-header {
        color: #FFF;
        text-align: center;
        h1 {
            font-size: 32px;
        }
    }
    .busca-evento {
        margin: 16px 0;
        margin-bottom: 32px;
        button, input, select {
            width: 100%;
        }
        .box-busca {
            background: transparent;
            border: none;
            input {
                background-color: transparent;
                color: #FFF;
            }
            .search-spinner {
                position: absolute;
                right: 20px;
                bottom: 20px;
                padding: 10px 10px 8px;
                .MuiCircularProgress-colorPrimary {
                    color: #FFF
                }
            }
        }
    }
    .lista-people {
        height: calc(80vh - 219px);
        overflow-y: auto;
        ul {
            padding: 0;
        }
        .people-row {
            display: flex;
            justify-content: center;
            align-items: center;
            &.header {
                font-weight: bold;
                font-size: 16px;
                padding-bottom: 8px;
                margin-bottom: 8px;
                border-bottom: 1px solid #FFF;
            }
            p, div {
                color: #FFF;
                flex: 1;
                flex-grow: 1;
                display: inline-block;
                &.name {
                    flex-grow: 3;
                }
                &.email {
                    flex-grow: 2;
                }
                &.social {
                    flex-grow: 1;
                    text-align: center;
                    a {
                        color: #FFF;
                        display: inline-block;
                        margin-left: 16px;
                        &.disabled {
                            opacity: .2;
                            cursor: default;
                        }
                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
        .infinite-box {
            display: flex;
            justify-content: center;
            padding-top: 16px;
            .MuiCircularProgress-colorPrimary {
                color: #FFF
            }
        }
    }
}

.container-network  {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (orientation: landscape) and (min-width: 960px) {

    #network-evento  .container-network {
        width: 960px;
        height: 540px;
    }

}
@media only screen and (orientation: portrait) and (min-height: 960px) {

    #network-evento  .container-network {
        height: 960px;
        width: 540px;
    }

}



/*------------------------------ 1024 ========================================= -*/

@media only screen and (orientation: landscape) and (min-width: 1024px) { 

    #network-evento  .container-network {
        width: 1024px;
        height: 576px;
    }
}
@media only screen and (orientation: portrait) and (min-height: 1024px) { 

    #network-evento  .container-network {
        height: 1024px;
        width: 576px;
    }
}


/*------------------------------ 1280 ========================================= -*/

@media only screen and (orientation: landscape) and (min-width: 1280px) { 

    #network-evento  .container-network{
        width: 1280px;
        height: 720px;
    }
}
@media only screen and (orientation: portrait) and (min-height: 1280px) { 

    #network-evento  .container-network{
        height: 1280px;
        width: 720px;
    }
}


/*------------------------------ 1600px ========================================= -*/

@media only screen and (orientation: landscape) and (min-width: 1600px) {

    #network-evento  .container-network{
        width: 1600px;
        height: 900px;
    }
}
@media only screen and (orientation: portrait) and (min-height: 1600px) {

    #network-evento  .container-network{
        height: 1600px;
        width: 900px;
    }
}

/*------------------------------ 1600px ========================================= -*/

@media only screen and (orientation: landscape) and (min-width: 1920px) {

    #network-evento  .container-network {
        width: 1920px;
        height: 100%;
    }
}

@media only screen and (orientation: portrait) and (min-height: 1920px) {

    #network-evento  .container-network {
        height: 1920px;
        width: 100%;
    }
}

.login-page {
    background: url('../../../assets/images/background.jpg') no-repeat 50%;
    background-size: 100%;
    @media only screen and (max-width: 960px){
        background-size: auto 100%;
    }
    .MuiPaper-root.login-card {
        min-height: 300px;
        background-color: rgba(0,0,0,0.5);
        display: flex;
        flex-direction: column;
        justify-content: center;
        .content-header {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            padding: 48px;
            margin-bottom: 16px;
            .cms-label {
                color: #fff;
                font-weight: bold;
                line-height: 13px;
            }
        }
    }
}

#mouse-over-popover {
    .MuiPopover-paper {
        padding: 16px;
        font-size: 12px
    }
}
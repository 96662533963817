.box-evento {
    position: relative;
    cursor: pointer;
    figure {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        overflow: hidden;
        height: 150px;
        border-radius: 6px;
        margin-bottom: 0;
        img {
            height: 100%;
            width: auto;
        }
    }
    .metadata-box {
        padding: 4px;
    }
    .box-desc {
        padding: 0;
        h1 {
            font-size: 15px;
            padding-right: 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            height: 36px;
            display: -webkit-box;
        }
        p {
            font-size: 12px;
            color: dimgrey;
        }
    }
    .box-data {
        padding: 0;
        h5 { 
            font-size: 18px;
            width: 110%;
            display: flex;
            justify-content: center;
        }
        h3 { font-size: 24px; }
        .fa {
            margin-top: 5px;
            margin-right: 3px;
            color: #5C49E1;
            font-size: 12px;
            text-align: left;
            float: left;
        }
    }
    .fa-action {
        position: absolute;
        right: 8px;
        bottom: 6px;
        width: 26px;
        height: 26px;
        background: #f3f5f7;
        padding: 5px 9px; transition: 0.3s;
        border-radius: 6px;
        &:hover {
            background: #7163e2;
            color: #fff;
        }
    }
}
.list-eventos-destaques .box-evento {
    background: #fff;
    border-radius: 6px;
    border: 2px solid #fff;
    transition: 0.3s;
    padding: 5px 5px 13px 5px;
    box-shadow: 10px 10px 55px 0px rgba(0,0,0,0.02);
    &:hover {
        border: 2px solid #f1654c;
        box-shadow: 10px 10px 55px 0px rgba(0,0,0,0.12);
    }
}
.list-eventos .box-evento,
.cms-list-eventos .box-evento {
    background: #f3f5f7;
    border-radius: 6px;
    margin-bottom: 35px;
    border: 2px solid #fff;
    padding: 5px;
    box-shadow: 10px 10px 55px 0px rgba(0,0,0,0.02);
    transition: 0.3s;
    &:hover {
        border: 2px solid #5c49e1;
        box-shadow: 10px 10px 55px 0px rgba(0,0,0,0.22);
    }
    .box-data {
        background: #f3f5f7;
    }
    .fa-action {
        background: #fff;
        &:hover {
            background: #7163e2;
            color: #fff;}
        }
}

.cms-list-eventos .box-evento {
    max-width: 285px;
    margin: 20px auto;
}
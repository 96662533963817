.slide-internas  {
    width: 100%;
    text-align: center;
    position: relative;
    display: block;
    float: left; 
    height: 350px;
    background: black url('https://blog.e-inscricao.com/wp-content/uploads/2017/03/como-manter-a-organizacao-de-um-evento-enquanto-ele-e-realizado.jpeg') no-repeat center; 
    padding-top: 150px;
    margin-top: -100px;
    h1 {
        width: 100%;
        padding-top: 24px;
        text-align: center;
        font-weight: bold;
        color: #F1654C
    }
    p {
        width: 100%;
        text-align: center;
        color: #F1654C;
        font-weight: 400;
    }
}
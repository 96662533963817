@import '../node_modules/@zoomus/websdk/dist/css/react-select.css';
@import '../node_modules/@zoomus/websdk/dist/css/bootstrap.css';

@import './assets/styles/tools/bootstrap.min.css';

@import './assets/styles/tools/fonts.styles.scss';
@import './assets/styles/base/base.scss';
@import './assets/styles/objects/button.styles.scss';
@import './assets/styles/objects/page-header.scss';
@import './assets/styles/objects/modal.styles.scss';
@import './assets/styles/objects/typography.styles.scss';

.App {
  min-height: 100vh;
  &.black-bg {
    background-color: #000;
  }
}
#hall-stands-gold  {
    background-color: #000;
    height: 900px;
    width: 1600px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    .container-hall-stands{
        background-repeat: no-repeat;
        background-size: auto 100%;
        width: 1600px;
        position: relative;
        height: 900px;
        margin: 0 auto;
        overflow: hidden;
        a {
            display: flex;
            height: 100%;
        }
    }
    .fade-in {
        -webkit-animation: fadein 1s;
        -moz-animation: fadein 1s;
        animation: fadein 1s;
        opacity: 1;
    }
    @keyframes fadein {
        0% { opacity: 1;  }
        50%   { opacity: 0; }
        100%   { opacity: 1; }
    }
    @-moz-keyframes fadein {
        0% { opacity: 1;  }
        50%   { opacity: 0; }
        100%   { opacity: 1; }
    }
    @-webkit-keyframes fadein {
        0% { opacity: 1;  }
        50%   { opacity: 0; }
        100%   { opacity: 1; }
    }
    .stand {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        cursor: pointer;
        display: block;
        position: absolute;
        z-index: 999;
        float: left;
    }
    .door {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        cursor: pointer;
        display: block;
        position: absolute;
        z-index: 999;
        float: left;
    }
    .painel {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        cursor: pointer;
        display: block;
        position: absolute;
        z-index: 999;
        float: left;
    }
    .centro {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        cursor: pointer;
        display: block;
        position: absolute;
        z-index: 999;
        float: left;
        width: 167px;
        height: 90px;
        top: 315px;
        left: 719px;
    }
    .left-painel {
        width: 157px;
        height: 79px;
        top: 183px;
        left: 546px;
    }
    .right-painel {
        width: 157px;
        height: 79px;
        top: 183px;
        right: 541px;
    }
    .left-door {
        width: 99px;
        height: 69px;
        top: 365px;
        left: 580px;
    }
    .right-door {
        width: 99px;
        height: 69px;
        top: 365px;
        right: 580px;
    }
    .stand-1 {
        width: 229px;
        height: 195px;
        top: 435px;
        left: 434px;
    }
    .stand-2 {
        width: 309px;
        height: 245px;
        top: 590px;
        left: 124px;
    }
    .stand-3 {
        width: 229px;
        height: 195px;
        top: 435px;
        right: 434px;
    }
    .stand-4 {
        width: 309px;
        height: 245px;
        top: 590px;
        right: 124px;
    }
}
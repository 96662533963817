#hall-amib-em-cena {
    background-color: #000;
    height: 900px;
    width: 1600px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    .container-hall-stands{
        background-repeat: no-repeat;
        background-size: auto 100%;
        width: 1600px;
        position: relative;
        height: 900px;
        margin: 0 auto;
        overflow: hidden;
        a {
            display: flex;
            height: 100%;
        }
    }
    .fade-in {
        -webkit-animation: fadein 1s;
        -moz-animation: fadein 1s;
        animation: fadein 1s;
        opacity: 1;
    }
    @keyframes fadein {
        0% { opacity: 1;  }
        50%   { opacity: 0; }
        100%   { opacity: 1; }
    }
    @-moz-keyframes fadein {
        0% { opacity: 1;  }
        50%   { opacity: 0; }
        100%   { opacity: 1; }
    }
    @-webkit-keyframes fadein {
        0% { opacity: 1;  }
        50%   { opacity: 0; }
        100%   { opacity: 1; }
    }
    .door {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        cursor: pointer;
        display: block;
        position: absolute;
        z-index: 999;
        float: left;
    }
    .painel {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        cursor: pointer;
        display: block;
        position: absolute;
        z-index: 999;
        float: left;
    }
    .left-painel {
        width: 150px;
        height: 102px;
        top: 470px;
        left: 80px;
    }
    .right-painel {
        width: 150px;
        height: 102px;
        top: 470px;
        right: 72px;
    }
    .door-1 {
        width: 136px;
        height: 169px;
        top: 440px;
        left: 220px;
    }
    .door-2 {
        width: 138px;
        height: 135px;
        top: 396px;
        left: 733px;
    }
    .door-3 {
        width: 136px;
        height: 169px;
        top: 440px;
        right: 228px;
    }
    .door-4 {
        width: 130px;
        height: 159px;
        top: 245px;
        left: 334px;
    }
    .door-5 {
        width: 130px;
        height: 159px;
        top: 245px;
        right: 340px;
    }
}
* {
  font-family: 'Roboto', sans-serif;
  text-decoration: none!important;
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
}

html {
    font-size: 100%;
}
body {
    min-height: 100vh;
    background: #fff;
}

p {
    margin: 0
}
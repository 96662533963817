.MuiButton-root {
    color: rgba(255, 255, 255, 0.87);
    &.MuiButton-containedPrimary {
        background-color: #a34100,
    }
    &.MuiButton-containedPrimary:hover {
        background-color: #712e01,
    }
    &.MuiButton-textPrimary {
        color: #a34100,
    }
}
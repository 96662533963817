.language-page {
    .MuiAlert-root.MuiAlert-standardError {
        margin-bottom: 0;
        margin-top: 8px;
    }
    .upload-button-wrapper {
        text-align: center;
    }
    .download-link {
        color: gray;
        &:hover {
            text-decoration: underline !important;
        }
    }
    .upload-button {
        margin-bottom: 8px;
    }
}
#hall-stands-bronze-2  {
    background-color: #000;
    height: 900px;
    width: 1600px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    .container-hall-stands{
        background-repeat: no-repeat;
        background-size: auto 100%;
        width: 1600px;
        position: relative;
        height: 900px;
        margin: 0 auto;
        overflow: hidden;
        a {
            display: flex;
            height: 100%;
        }
    }
    .fade-in {
        -webkit-animation: fadein 1s;
        -moz-animation: fadein 1s;
        animation: fadein 1s;
        opacity: 1;
    }
    @keyframes fadein {
        0% { opacity: 1;  }
        50%   { opacity: 0; }
        100%   { opacity: 1; }
    }
    @-moz-keyframes fadein {
        0% { opacity: 1;  }
        50%   { opacity: 0; }
        100%   { opacity: 1; }
    }
    @-webkit-keyframes fadein {
        0% { opacity: 1;  }
        50%   { opacity: 0; }
        100%   { opacity: 1; }
    }
    .stand {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        cursor: pointer;
        display: block;
        position: absolute;
        z-index: 999;
        float: left;
    }
    .door {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        cursor: pointer;
        display: block;
        position: absolute;
        z-index: 999;
        float: left;
    }
    .painel {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        cursor: pointer;
        display: block;
        position: absolute;
        z-index: 999;
        float: left;
    }
    .centro {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        cursor: pointer;
        display: block;
        position: absolute;
        z-index: 999;
        float: left;
        width: 167px;
        height: 90px;
        top: 255px;
        left: 745px;
    }
    .left-painel {
        width: 165px;
        height: 89px;
        top: 118px;
        left: 559px;
    }
    .right-painel {
        width: 165px;
        height: 89px;
        top: 118px;
        right: 501px;
    }
    .left-door {
        width: 99px;
        height: 69px;
        top: 305px;
        left: 605px;
    }
    .right-door {
        width: 99px;
        height: 69px;
        top: 305px;
        right: 555px;
    }
    .stand-1 {
        width: 229px;
        height: 195px;
        top: 365px;
        left: 374px;
    }
    .stand-2 {
        width: 309px;
        height: 245px;
        top: 440px;
        left: 124px;
    }
    .stand-3 {
        width: 189px;
        height: 145px;
        top: 314px;
        right: 404px;
    }
    .stand-4 {
        width: 259px;
        height: 175px;
        top: 400px;
        right: 264px;
    }
    .stand-5 {
        width: 379px;
        height: 305px;
        top: 485px;
        right: 70px;
    }
}
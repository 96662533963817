.navigation-controls {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 80px;
    max-width: 1600px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    flex-direction: column;
    background-color: transparent;
    padding: 20px 0;
    .arrow-control {
        margin: 20px 0;
    }
    .icon-outer {
        border-radius: 50%;
        border: 4px solid #FFF;
        background: linear-gradient(rgba(0,0,0,0.5018382352941176) 0%, rgba(0,0,0,0.5018382352941176) 25%, rgba(0,212,255,0) 100%); 
        padding: 4px;
        margin-right: 0;
        margin-bottom: 10px;
        cursor: pointer;
        svg {
            color: #FFF;
            font-size: 1.5rem;
        }
        &:hover {
            border-color:#000;
            background: #FFF;
            svg {
                color: #000;
            }
        }
    }
}

@media screen and (min-width: 600px) {
    .navigation-controls {
        flex-direction: row;
        padding: 0 20px;
        left: 50%;
        height: 80px;
        width: 100%;
        transform: translateX(-50%);
        .arrow-control {
            display: flex;
            margin: 0 20px;
        }
        .icon-outer {
            margin-bottom: 0;
            margin-right: 10px;
            svg {
                font-size: 2.1875rem;
            }
        }
    }
}
#stand-evento  {
    background-color: #000;
    height: 900px;
    width: 1600px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    .container-stand { 
        background-repeat: no-repeat;
        background-size: auto 100%;
        width: 1600px;
        position: relative;
        height: 900px;
        margin: 0 auto;
        overflow: hidden;
        .website {    
            background-repeat: no-repeat;
            background-size: 100% 100%;
            z-index: 99!important;
            width: 267px;
            height: 155px;
            display: block;
            position: absolute;
            z-index: 999;
            float: left;
            top: 138px;
            left: 845px;
        }
        .negotiation {    
            background-repeat: no-repeat;
            background-size: 100% 100%;
            z-index: 99 !important;
            width: 113px;
            height: 248px;
            display: block;
            position: absolute;
            z-index: 999;
            float: left;
            top: 346px;
            left: 646px;
            &.-master {
                width: 133px;
                height: 98px;
                top: 351px;
                left: 469px;
            }
        }
        .symposium {    
            background-repeat: no-repeat;
            background-size: 100% 100%;
            z-index: 99 !important;
            width: 113px;
            height: 125px;
            display: block;
            position: absolute;
            z-index: 999;
            float: left;
            top: 346px;
            left: 646px;
        }
        .form {
            background-repeat: no-repeat;
            background-size: 100% 100%;
            z-index: 99 !important;
            width: 133px;
            height: 98px;
            display: block;
            position: absolute;
            z-index: 999;
            float: left;
            top: 351px;
            left: 469px;
            &.-master {
                width: 113px;
                height: 124px;
                top: 471px;
                left: 646px;
            }
        }
        .chat {
            background-repeat: no-repeat;
            background-size: 100% 100%;
            z-index: 99 !important;
            width: 133px;
            height: 98px;
            display: block;
            position: absolute;
            z-index: 999;
            float: left;
            top: 466px;
            left: 469px;
        }
        .media0 {
            background-repeat: no-repeat;
            background-size: 100% 100%;
            z-index: 99 !important;
            width: 154px;
            height: 120px;
            display: block;
            position: absolute;
            z-index: 999;
            float: left;
            top: 450px;
            left: 832px;
            &.-master {
                width: 97px;
            }
            &.-bronze {
                width: 309px;
            }
        }
        .media1 {
            background-repeat: no-repeat;
            background-size: 100% 100%;
            z-index: 99 !important;
            width: 154px;
            height: 120px;
            display: block;
            position: absolute;
            z-index: 999;
            float: left;
            top: 450px;
            left: 988px;
            &.-master {
                width: 106px;
                left: 932px;
            }
        }
        .media2 {
            background-repeat: no-repeat;
            background-size: 100% 100%;
            z-index: 99 !important;
            width: 100px;
            height: 120px;
            display: block;
            position: absolute;
            z-index: 999;
            float: left;
            top: 450px;
            left: 1041px;
        }
        .document0 {
            background-repeat: no-repeat;
            background-size: 100% 100%;
            z-index: 99 !important;
            width: 109px;
            height: 51px;
            display: block;
            position: absolute;
            z-index: 999;
            float: left;
            top: 663px;
            left: 927px;
            &.-master {
                width: 90px;
                left: 891px;
            }
            &.-bronze {
                left: 891px;
                width: 311px;
                height: 65px;
                top: 653px;
            }
        }
        .document1 {
            background-repeat: no-repeat;
            background-size: 100% 100%;
            z-index: 99 !important;
            width: 109px;
            height: 51px;
            display: block;
            position: absolute;
            z-index: 999;
            float: left;
            top: 663px;
            left: 1058px;
            &.-master {
                width: 90px;
                left: 1008px;
            }
        }
        .document2 {
            background-repeat: no-repeat;
            background-size: 100% 100%;
            z-index: 99 !important;
            width: 90px;
            height: 51px;
            display: block;
            position: absolute;
            z-index: 999;
            float: left;
            top: 663px;
            left: 1112px;
        }
        .social {
            background-repeat: no-repeat;
            background-size: 100% 100%;
            z-index: 99 !important;
            display: block;
            position: absolute;
            z-index: 999;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            cursor: pointer;
            a {
                display: flex;
                height: 100%;
            }
            &.instagram{
                top: 535px;
                left: 251px;
                &.-bronze {
                    top: 385px;
                    left: 680px;
                }
            }
            &.facebook{
                top: 605px;
                left: 251px;
                &.-bronze {
                    top: 452px;
                    left: 680px;
                }
            }
            &.youtube{
                top: 675px;
                left: 251px;
                &.-bronze {
                    top: 523px;
                    left: 680px;
                }
            }
        }
    }
    .menu-lateral {
        padding: 10px 12px;
        margin-top: 0;
        max-width: 200px;
        width: 80%;
        height: 100%;
        top: 0;
        left: -200px;
        position: absolute;
        z-index: 999;
        display: block;
        transition: all .3s ease;
        ul { 
            width: 100%;
            position: relative;
            display: block;
            float: left;
            padding:0;
            margin:0; 
            li {
                width: 100%;
                position: relative;
                display: block;
                float: left;
                //padding-left: 10px;
                color: #fff;
                transition: 0.3s;
                padding-top: 5px;
                padding-bottom: 5px;
                margin-bottom: 10px;
                border-radius: 40px;
                
                cursor: pointer;
                border: 2px solid #eee;
                background: #343a40c2;
                text-align: center;
                a {
                    color: #FFF;
                    display: block;
                }
                &:hover {
                    background: #212529;
                }
            }
        }
        .back-button {
            margin-top: 16px;
            .fa {
                margin-right: 8px;
            }
        }
    }
}

/* ------------------- MODAL  ------------------------------------- */

#modal {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    color: black; border-radius: 5px; transition: 0.3s;
}

#modal .modal-content{

    background: #fafafa;
    padding: 20px;
    border: 2px solid #F1654C;
    z-index: 999999;
    display: block;
    float: left;
    position: absolute;
    width: 800px;
    max-height: 80%;
    max-width: 90%;
    overflow-y: auto;
    border-radius: 5px;
}

#modal footer {background: none;
    padding: 15px 0;
    float: left;
    width: 100%;
    position: relative;
    display: block;}


#modal figure {padding: 15px 0;}

#modal .modal-content h1 {    
    font-size: 18px;
    border-left: 2px solid #ddd; 
    margin-top: 20px;
    padding: 2px;padding-left: 10px; 
}

#modal .modal-content h2 {    font-size: 14px;
    border-left: 2px solid #5c49e1; color: #5c49e1;
    padding: 2px; padding-left: 10px;}


#modal .desc {

  border-top: 2px solid #ddd;
    padding-top: 20px;
    margin-top: 20px;margin-bottom: 20px;

  }

#modal .btn-secondary {color: #fff;
    background-color: #000000;
    border-color: #000000; transition: 0.3s;
    padding: 8px 55px;}

#modal .btn-secondary:hover { background: #F1654C; border: 1px solid #F1654C;}

#modal .desc p {font-size: 15px;
    line-height: 31px;
    text-align: justify;
    color: #8f8989;}

.hall-stands {
  width: 100%;
 padding-bottom: 55px;
  position: relative;
  display: block;
  float: left;
  margin-top: 30px;
  background: #efefef;
}

.hall-stands figure { margin-top: 30px; margin-bottom: 30px; }

.hall-stands h1 {
font-size: 18px;
    padding: 10px 45px;
    background: #fff;
    color: #ffa800;
    vertical-align: middle;
    display: inline-block;
    border-radius: 0px 0px 15px 15px;
 
}

.logo-stands {width: 100%; position: relative; display: block; float: left; margin-top: 60px;}

.hall-stands-normal {
  width: 100%;
 padding-bottom: 55px;
  position: relative;
  display: block;
  float: left;
  border-top: 2px solid #e3e1e1;
  background: #fff;
}
.hall-stands-normal figure { margin-top: 20px; margin-bottom: 20px; }

.hall-stands-normal h1 {
font-size: 18px;
    padding: 10px 45px;
    background: #e3e1e1;
    color: black;
    vertical-align: middle;
    display: inline-block;
    border-radius: 0px 0px 15px 15px;
}

.hall-stands-normal figure img{ width: 67%;
    filter: grayscale(1);  }

#modal {
    .modal-content {
        background: #fafafa;
        padding: 20px;
        border: 2px solid #F1654C;
        z-index: 99999;
        display: block;
        float: left;
        position: absolute;
        h1 {    
            font-size: 18px;
            border-left: 2px solid #ddd;
            margin-top: 20px;
            padding: 2px;
            padding-left: 10px;
        }
        h2 {
            font-size: 14px;
            border-left: 2px solid #5c49e1;
            color: #5c49e1;
            padding: 2px;
            padding-left: 10px;
        }
        .desc {
            border-top: 2px solid #ddd;
            padding-top: 20px;
            margin-top: 20px;margin-bottom: 20px;
            p {
                font-size: 15px;
                line-height: 31px;
                text-align: justify;
                color: #8f8989;
            }
        }
        figure {
            img {
                height: auto;
                width: 100%;
            }
        }
        .btn-secondary {
            color: #fff;
            background-color: #000000;
            border-color: #000000;
            transition: 0.3s;
            padding: 8px 55px;
            &:hover {
                background: #F1654C;
                border: 1px solid #F1654C;
            }
        }
    }
    .produtos {
        padding-top: 0;
        margin-top: 0;
        margin-bottom: 20px;
        float: left;
        width: 100%;
        h1 {
            font-size: 18px;
            border-bottom: 2px solid #F1654C;
            border-left: 0;
            margin-bottom: 20px;
            padding: 2px;
            padding-left: 10px;
        }
        .box-produto { 
            width: 100%;
            position: relative;
            display: block;
            float: left;
            padding: 16px 8px;
            border-bottom: 2px solid #ddd;
            &:last-child {
                border-bottom: none;
            }
            h1 {
                font-size: 18px;
                padding: 0;
                border: none;
                margin-bottom: 0;
                margin-top: 0;
            }
            p {
                font-size: 14px;
                padding: 0;
                border: none;    
                color: #9c9b9b;
                margin-bottom: 0;
            }
            .preco {
                margin-top: 6px;
                background: #dddddd;
                border-radius: 6px;
                padding: 8px 15px;
                font-weight: bold;
                text-align: right;
                .with-discount {
                    font-size: 9px;
                } 
                span {
                    display: block;
                }
                .discount {
                    font-size: 14px;
                }
            }
            .button {
                margin-top: 8px;
                display: block;
                text-align: center;
                padding: 8px 15px;
                background: #F1654C;
                color: #FFF;
                border-radius: 5px;
                &:hover{
                    background: #d63b20;
                }
            }
        }
        figure {
            height: 100px;
            overflow: hidden;
            background: #ddd;
            border-radius: 5px;
            margin: 0;
            padding: 0;
        }
        ul {
            width: 100%;
            position: relative;
            display: block;
            float: left;
            padding: 0;
            margin: 0;
            li{
                padding: 10px;
                position: relative;
                display: block;
                float: left;
                .img-stand { 
                    position: relative;
                    display: block;
                    float: left;
                    height: 100px;
                    img{ 
                        height: 100%;
                        width: auto
                    }
                }
            }
        }
    }
    footer {
        background: none;
        padding: 15px 0;
        border-top: 1px solid #ddd;
        float: left;
        width: 100%;
        position: relative;
        display: block;
    }

    &.modal-image {
        img, video {
            width: 100%;
            height: auto;
        }
        .image-description {
            font-size: 12px;
            padding: 8px;
        }
    }
    &.modal-hall-principal {
        z-index: 99999;
        .modal-content {
            background: transparent;
            padding: 0;
            width: auto;
            border: none;
            border-radius: 0;
            z-index: 99999;
            &.map-nav{
                max-height: 90%;
                width: 1024px;
            }
        }
        img, video {
            width: 100%;
            height: 100%;
        }
        video {
            pointer-events: none;
        }
        .image-description {
            font-size: 12px;
            padding: 8px;
        }
    }
}
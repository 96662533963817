@font-face {
  font-family: 'OpenSans-Regular';
  font-style: normal;
  font-weight: 400;
  src: local('OpenSans-Regular'), local('OpenSans-Regular')
       url('../../fonts/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSans-Bold';
  font-style: normal;
  font-weight: 400;
  src: local('OpenSans-Bold'), local('OpenSans-Bold'),
       url('../../fonts/OpenSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSans-Light';
  font-style: normal;
  font-weight: 400;
  src: local('OpenSans-Light'), local('OpenSans-Light'),
       url('../../fonts/OpenSans-Light.ttf') format('truetype');
}
.image-wrapper {
    position: relative;
    min-height: 250px;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    &.-small {
        overflow: hidden;
        min-height: 140px;
    }
    .form-image {
        height: 250px;
        width: auto;
        &.-full-width {
            width: 100%
        }
        &.-small {
            height: 140px;

        }
    }
    &.-video {
        overflow: visible;
    }
    .documents {
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .overlay-image {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.3);
        &.-video {
            z-index: 1;
            height: 20px;
            top: 20px;
            background-color: transparent;
        }
    }
    &:hover {
        .overlay-image {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
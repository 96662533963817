#hall-rooms  {
    background-color: #000;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    .container-hall-rooms{
        background-color: #000;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
        position: relative;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        width: 320px;
        height: 180px;
    }

    .floating {
        margin: 16px 16px 0;
        background: rgba(74,74,74,0.7);
        border-radius: 5px;
        max-height: 80vh;
        overflow: auto;
        flex-direction: column;
        .list-titulo {
            padding: 16px;
        }
        .m-label-sala {
            display: block;
            padding: 8px 8px 0;
            border-bottom: 1px solid;
            margin: 8px 16px;
            color: #FFF;
            &:first-child {
                padding-top: 0;
                margin-top: 0;
            }
        }
        .m-title-sala {
            color: #FFF;
            font-weight: bold;
        }
        .turn-back:not(.mobile) {
            display: none;
        }
        .keep-going:not(.mobile) {
            display: none;
        }

        .tvs-mob {
            display: flex;
            justify-content: space-around;
            width: 100%;
            .tv1.mob, .tv2.mob {
                position: static;
                margin: 16px;
            }
        }
    }
    .mobile-controllers {
        margin: 0 16px;
        display: flex;
        justify-content: space-between;
        .turn-back.mobile,
        .keep-going.mobile {
            .fa {
                height: 40px;
            }
            p {
                margin-top: -10px;
            }
        }
        .keep-going.mobile {
            text-align: right;
        }
    }
        
    .titulo0, .titulo1, .titulo2 {
        padding: 0 8px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
    }
    .tv1, .tv2 {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
    }
    .banner {
        background-size: 100% 100%;
    }
    .sala-dummy {
        cursor: default;
    }
    .keep-going:not(.mobile) {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 25%;
        width: 150px;
        max-width: 25%;
        display: flex;
        justify-content: center;
        align-items: baseline;
        flex-direction: column;
        z-index: 100;
        cursor: pointer;
        .fa {
            transition: transform .4s ease;
            height: 120px;
        }
        p {
            opacity: 0;
            transition: opacity .4s ease;
            color: #FFF;
            font-weight: bold;
        }
        &:hover {
            p {
                opacity: 1
            }
            .fa {
                transform: translateX(16px);
            }
        }
    }
    .turn-back:not(.mobile) {
        position: absolute;
        cursor: pointer;
        bottom: 0;
        left: 5px;
        height: 25%;
        width: 150px;
        max-width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 100;
        .fa {
            transition: transform .4s ease;
            height: 120px;
        }
        p {
            opacity: 0;
            transition: opacity .4s ease;
            color: #FFF;
            font-weight: bold;
        }
        &:hover {
            p {
                opacity: 1
            }
            .fa {
                transform: translateX(-16px);
            }
        }
    }
    .fade-in {
        -webkit-animation: fadein 1s;
        -moz-animation: fadein 1s;
        animation: fadein 1s;
        opacity: 1;
    }
    @keyframes fadein {
        0% { opacity: 1;  }
        50%   { opacity: 0; }
        100%   { opacity: 1; }
    }
    @-moz-keyframes fadein {
        0% { opacity: 1;  }
        50%   { opacity: 0; }
        100%   { opacity: 1; }
    }
    @-webkit-keyframes fadein {
        0% { opacity: 1;  }
        50%   { opacity: 0; }
        100%   { opacity: 1; }
    }
}

@media only screen and (orientation: landscape) and (min-width: 640px) {

    #hall-rooms  .container-hall-rooms {
        width: 640px;
        height: 360px;
    }


    .titulo0 {         
        font-size: 12px;
        text-align: center;
        padding: 5px;
        position: absolute;
        width: 100px;
        height: 24px;
        top: 99px;
        left: 15px;
        font-weight: bold;
    }


    .sala0{     
        width: 86px;
        height: 135px;
        display: block;
        position: absolute;
        z-index: 9999;
        float: left;
        top: 145px;
        left: 27px;
        cursor: pointer;
    }


    .tv1 {     
        width: 62px;
        height: 38px;
        display: block;
        position: absolute;
        z-index: 9999;
        float: left;
        top: 138px;
        left: 134px;
    }

    .titulo1{         
        font-size: 12px;
        text-align: center;
        padding: 5px;
        position: absolute;
        width: 100px;
        height: 24px;
        top: 99px;
        left: 215px;
        font-weight: bold;
    }
    .sala1{     
        width: 94px;
        height: 135px;
        display: block;
        position: absolute;
        z-index: 9999;
        float: left;
        top: 145px;
        left: 217px;
        cursor: pointer;
    }

    .tv2 {     
        width: 62px;
        height: 38px;
        display: block;
        position: absolute;
        z-index: 9999;
        float: left;
        top: 138px;
        left: 333px;
    }


    .titulo2 {         
        font-size: 12px;
        text-align: center;
        padding: 5px;
        position: absolute;
        width: 100px;
        height: 24px;
        top: 99px;
        right: 127px;
        font-weight: bold;
    }
    .sala2{     
        width: 94px;
        height: 135px;
        display: block;
        position: absolute;
        z-index: 9999;
        float: left;
        top: 145px;
        right: 131px;
        cursor: pointer;
    }


    .banner {     
        width: 94px;
        height: 134px;
        display: block;
        position: absolute;
        z-index: 9999;
        float: left;
        top: 146px;
        right: 11px;
    }
}

@media only screen and (orientation: portrait) and (min-height: 640px) {

    #hall-rooms  .container-hall-rooms {
        height: 640px;
        width: 360px;
    }

    .tv1 {     
        width: 63px;
        height: 38px;
        display: block;
        position: absolute;
        z-index: 9999;
        float: left;
        top: 117px;
        left: 133px;
    }

    .tv2 {     
        width: 63px;
        height: 38px;
        display: block;
        position: absolute;
        z-index: 9999;
        float: left;
        top: 117px;
        left: 332px;
    }
}

/*------------------------------ 960 ========================================= -*/

@media only screen and (orientation: landscape) and (min-width: 960px) {

    #hall-rooms  .container-hall-rooms {
        width: 960px;
        height: 540px;
    }

    #hall-rooms .titulo0,
    #hall-rooms .titulo1,
    #hall-rooms .titulo2 {
        font-size: 12px;
        line-height: 1.2;
    }
    .titulo0 {         
        font-size: 12px;
        text-align: center;
        padding: 5px;
        position: absolute;
        width: 150px;
        height: 38px;
        top: 147px;
        left: 22px;
        font-weight: bold;
    }


    .sala0 {
        width: 130px;
        height: 200px;
        display: block;
        position: absolute;
        z-index: 99;
        float: left;
        top: 217px;
        left: 40px;
    }


    .tv1 {
        width: 94px;
        height: 57px;
        display: block;
        position: absolute;
        z-index: 99;
        float: left;
        top: 206px;
        left: 200px;
    }


    .sala1{     
        width: 137px;
        height: 200px;
        display: block;
        position: absolute;
        z-index: 99;
        float: left;
        top: 217px;
        left: 329px;
    }

    .titulo1{         
        font-size: 12px;
        text-align: center;
        padding: 5px;
        position: absolute;
        width: 151px;
        height: 39px;
        top: 146px;
        left: 320px;
        font-weight: bold;
    }

    .tv2 {
        width: 94px;
        height: 57px;
        display: block;
        position: absolute;
        z-index: 99;
        float: left;
        top: 206px;
        left: 497px;
    }



    .sala2{
        width: 136px;
        height: 200px;
        display: block;
        position: absolute;
        z-index: 99;
        float: left;
        top: 217px;
        right: 200px;
    }

    .titulo2 {         
        font-size: 12px;
        text-align: center;
        padding: 5px;
        position: absolute;
        width: 152px;
        height: 38px;
        top: 147px;
        left: 618px;
        font-weight: bold;
    }

    .banner {     
        width: 143px;
        height: 201px;
        display: block;
        position: absolute;
        z-index: 99;
        float: left;
        top: 218px;
        right: 15px;
    }



}

@media only screen and (orientation: portrait) and (min-height: 960px) {

    #hall-rooms  .container-hall-rooms {
        height: 960px;
        width: 540px;
    }

    .tv1 {     
        width: 101px;
        height: 61px;
        display: block;
        position: absolute;
        z-index: 99;
        float: left;
        top: 316px;
        left: 213px; 
    }

    .tv2 {     
        width: 101px;
        height: 61px;
        display: block;
        position: absolute;
        z-index: 99;
        float: left;
        top: 316px;
        left: 530px;
    }
}


/*------------------------------ 1024 ========================================= -*/

@media only screen and (orientation: landscape) and (min-width: 1024px) { 

    #hall-rooms  .container-hall-rooms {
        width: 1024px;
        height: 576px;
    }
    #hall-rooms .titulo0,
    #hall-rooms .titulo1,
    #hall-rooms .titulo2 {
        font-size: 14px;
    }
    .sala0{     
        width: 138px;
        height: 213px;
        display: block;
        position: absolute;
        z-index: 99;
        float: left;
        top: 233px;
        left: 42px;
    }

    .titulo0 {       
        font-size: 12px;
        text-align: center;
        line-height: 1.2;
        padding: 10px;
        position: absolute;
        width: 157px;
        height: 40px;
        top: 157px;
        left: 28px;
        font-weight: bold;
    }

    .tv1 {     
        width: 101px;
        height: 61px;
        display: block;
        position: absolute;
        z-index: 99;
        float: left;
        top: 220px;
        left: 213px; 
    }

    .titulo1 {       
        font-size: 12px;
        text-align: center;
        line-height: 1.2;
        padding: 10px;
        position: absolute;
        width: 157px;
        height: 40px;
        top: 157px;
        left: 346px;
        font-weight: bold;
    }
    .sala1{     
        width: 147px;
        height: 213px;
        display: block;
        position: absolute;
        z-index: 99;
        float: left;
        top: 233px;
        left: 351px;
    }

    .tv2 {     
        width: 101px;
        height: 61px;
        display: block;
        position: absolute;
        z-index: 99;
        float: left;
        top: 220px;
        left: 530px;
    }



    .sala2{
        width: 142px;
        height: 213px;
        display: block;
        position: absolute;
        z-index: 99;
        float: left;
        top: 233px;
        right: 216px;
    }

    .titulo2 {        
        font-size: 12px;
        text-align: center;
        line-height: 1.2;
        padding: 10px;
        position: absolute;
        width: 157px;
        height: 40px;
        top: 157px;
        left: 661px;
        font-weight: bold;
    }

    .banner {
        width: 153px;
        height: 214px;
        display: block;
        position: absolute;
        z-index: 99;
        float: left;
        top: 232px;
        right: 16px;
    }
}

@media only screen and (orientation: portrait) and (min-height: 1024px) { 

    #hall-rooms  .container-hall-rooms {
        height: 1024px;
        width: 576px;
    }

    .tv1 {     
        width: 101px;
        height: 61px;
        display: block;
        position: absolute;
        z-index: 99;
        float: left;
        top: 316px;
        left: 213px; 
    }

    .tv2 {     
        width: 101px;
        height: 61px;
        display: block;
        position: absolute;
        z-index: 99;
        float: left;
        top: 316px;
        left: 530px;
    }
}

/*------------------------------ 1280 ========================================= -*/

@media only screen and (orientation: landscape) and (min-width: 1280px) { 


#hall-rooms  .container-hall-rooms{

    width: 1280px;
    height: 720px;
}

    #hall-rooms .titulo0,
    #hall-rooms .titulo1,
    #hall-rooms .titulo2 {
        font-size: 16px;
    }




 .sala0{     
   width: 173px;
    height: 265px;
    display: block;
    position: absolute;
    z-index: 99;
    float: left;
    top: 289px;
    left: 53px;
}

.titulo0 {       font-size: 16px;
    text-align: center;
    padding: 13px;
    position: absolute;
    width: 199px;
    height: 50px;
    top: 196px;
    left: 30px;
    font-weight: bold;
}

 .tv1 {     
  width: 126px;
    height: 77px;
    display: block;
    position: absolute;
    z-index: 99;
    float: left;
    top: 274px;
    left: 266px;
}


 .sala1{     
     width: 185px;
    height: 265px;
    display: block;
    position: absolute;
    z-index: 99;
    float: left;
    top: 289px;
    left: 438px;
}
.titulo1 {       font-size: 16px;
    text-align: center;
    padding: 13px;
    position: absolute;
    width: 199px;
    height: 50px;
    top: 196px;
    left: 428px;
    font-weight: bold;
}
 .tv2 {     
width: 126px;
    height: 77px;
    display: block;
    position: absolute;
    z-index: 99;
    float: left;
    top: 274px;
    left: 663px;
}



 .sala2{     
    width: 185px;
    height: 265px;
    display: block;
    position: absolute;
    z-index: 99;
    float: left;
    top: 289px;
    right: 263px;
}
.titulo2 {       font-size: 16px;
    text-align: center;
    padding: 13px;
    position: absolute;
    width: 199px;
    height: 50px;
    top: 196px;
   left: 825px;
    font-weight: bold;
}

 .banner {     
  width: 189px;
    height: 268px;
    display: block;
    position: absolute;
    z-index: 99;
    float: left;
    top: 290px;
    right: 21px;
}



    }

@media only screen and (orientation: portrait) and (min-height: 1280px) { 

    #hall-rooms  .container-hall-rooms{

        height: 1280px;
        width: 720px;
    }

    .tv1 {     
        width: 126px;
        height: 77px;
        display: block;
        position: absolute;
        z-index: 99;
        float: left;
        top: 274px;
        left: 266px;
    }

    .tv2 {     
        width: 126px;
        height: 77px;
        display: block;
        position: absolute;
        z-index: 99;
        float: left;
        top: 274px;
        left: 663px;
    }
}

/*------------------------------ 1600px ========================================= -*/

@media only screen and (orientation: landscape) and (min-width: 1600px) {


#hall-rooms  .container-hall-rooms{

    width: 1600px;
    height: 900px;
}

#hall-rooms .titulo0,
    #hall-rooms .titulo1,
    #hall-rooms .titulo2 {
        font-size: 18px;
    }

.titulo0 {    font-size: 16px;
    text-align: center;
    padding: 20px;
    position: absolute;
    width: 248px;
    top: 243px;
    left: 39px;
    font-weight: bold;
}


 .sala0{     
    width: 216px;
    height: 332px;
    display: block;
    position: absolute;
    z-index: 99;
    float: left;
    top: 362px;
    left: 67px;
}


 .tv1 {     
    width: 157px;
    height: 94px;
    display: block;
    position: absolute;
    z-index: 99;
    float: left;
    top: 344px;
    left: 333px;
}


 .sala1{     
    width: 230px;
    height: 332px;
    display: block;
    position: absolute;
    z-index: 99;
    float: left;
    top: 361px;
    left: 549px;
}

.titulo1 {    font-size: 16px;
    text-align: center;
    padding: 20px;
    position: absolute;
    width: 248px;
    top: 243px;
    left: 539px;
    font-weight: bold;
}

 .tv2 {     
width: 157px;
    height: 94px;
    display: block;
    position: absolute;
    z-index: 99;
    float: left;
    top: 344px;
    left: 828px;
}



 .sala2{     
width: 226px;
    height: 332px;
    display: block;
    position: absolute;
    z-index: 99;
    float: left;
    top: 361px;
    right: 336px;
}

.titulo2 {    font-size: 16px;
    text-align: center;
    padding: 20px;
    position: absolute;
    width: 248px;
    top: 243px;
    left: 1035px;
    font-weight: bold;
}

 .banner {     
   width: 241px;
    height: 340px;
    display: block;
    position: absolute;
    z-index: 99;
    float: left;
    top: 359px;
    right: 23px;
}




}

@media only screen and (orientation: portrait) and (min-height: 1600px) {

    #hall-rooms  .container-hall-rooms{
        height: 1600px;
        width: 900px;
    }

    .tv1 {     
        width: 157px;
        height: 94px;
        display: block;
        position: absolute;
        z-index: 99;
        float: left;
        top: 344px;
        left: 333px;
    }

    .tv2 {     
        width: 157px;
        height: 94px;
        display: block;
        position: absolute;
        z-index: 99;
        float: left;
        top: 344px;
        left: 828px;
    }
}
/* ---------------------------- */


/*------------------------------ 1600px ========================================= -*/

@media only screen and (orientation: landscape) and (min-width: 1920px) {


#hall-rooms  .container-hall-rooms {

    width: 1920px;
    height: 1080px;
}
    #hall-rooms .titulo0,
    #hall-rooms .titulo1,
    #hall-rooms .titulo2 {
        font-size: 22px;
    }

.titulo0 { font-size: 20px;
    text-align: center;
    padding: 20px;
    position: absolute;
    width: 288px;
    top: 295px;
    left: 50px;
    font-weight: bold;
}

 .sala0{     
width: 261px;
    height: 398px;
    display: block;
    position: absolute;
    z-index: 99;
    float: left;
    top: 439px;
    left: 78px;
}


 .tv1 {     
    width: 191px;
    height: 117px;
    display: block;
    position: absolute;
    z-index: 99;
    float: left;
    top: 411px;
    left: 398px;
  
}

.titulo1 { font-size: 20px;
    text-align: center;
    padding: 20px;
    position: absolute;
    width: 288px;
    top: 300px;
    left: 648px;
    font-weight: bold;
}

    .sala1{     
        width: 276px;
        height: 398px;
        display: block;
        position: absolute;
        z-index: 99;
        float: left;
        top: 439px;
        left: 658px;
    }

    .tv2 {     
        width: 191px;
        height: 117px;
        display: block;
        position: absolute;
        z-index: 99;
        float: left;
        top: 410px;
        left: 993px;
    }

    .sala2{     
        width: 276px;
        height: 398px;
        display: block;
        position: absolute;
        z-index: 99;
        float: left;
        top: 432px;
        right:397px;
    }

    .titulo2{ 
        font-size: 20px;
        text-align: center;
        padding: 20px;
        position: absolute;
        width: 288px;
        top: 301px;
        left: 1245px;
        font-weight: bold;
    }

    .banner {     
        width: 290px;
        height: 405px;
        display: block;
        position: absolute;
        z-index: 99;
        float: left;
        top: 433px;
        right: 28px;
    
    }

}

@media only screen and (orientation: portrait) and (min-height: 1920px) {

    #hall-rooms  .container-hall-rooms {
        height: 1920px;
        width: 1080px;
    }

    .tv1 {     
        width: 191px;
        height: 117px;
        display: block;
        position: absolute;
        z-index: 99;
        float: left;
        top: 411px;
        left: 398px;
    
    }

    .tv2 {     
        width: 191px;
        height: 117px;
        display: block;
        position: absolute;
        z-index: 99;
        float: left;
        top: 410px;
        left: 993px;
    }
}



/* :::::::::::::::::::::::::::::

  CSS VIEW PALESTRA DO EVENTO 

  ::::::::::::::::::::::::::;

/* PALESTRA DO EVENTO  ------------------------------------------------------------------------------------------------------------------------------------------------
------------------------- */



#palestra-evento  {
    background-color: #000;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}



.container-palestra{
    background-repeat: no-repeat;
    background-position: center;
}

.container-palestra {
    .turn-back {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 150px;
        max-width: 25%;
        height: 100px;
        max-height: 25%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        .fa {
            transition: transform .4s ease;
            position: absolute;
            height: 100%;
        }
        p {
            position: absolute;
            top: 0;
            opacity: 0;
            transition: opacity .4s ease;
            color: #FFF;
            font-weight: bold;
        }
        &:hover {
            p {
                opacity: 1
            }
            .fa {
                transform: translateY(16px);
            }
        }
    }
}

#palestra-evento .container-palestra{ background-color: #000;
       background-repeat: no-repeat;
    background-size: 100% auto;
   
    position: relative;
    
    margin: 0 auto;
    width: 320px;
    height: 180px;
}

.tela-palestra{     
    width: 126px;
    height: 64px;
    display: block;
    position: absolute;
    z-index: 9999;
    float: left;
    top: 59px;
    left: 98px;
}

.hour {
    position: absolute;
    left: 0;
    top: 0;
    color: #FFF;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.container-palestra  {
    display: flex;
    justify-content: center;
    align-items: center;
}


// ---------- 640px
@media only screen and (orientation: landscape) and (min-width: 640px) {

    #palestra-evento  .container-palestra {
        width: 640px;
        height: 360px;
    }



    .tela-palestra{     
        width: 257px;
        height: 127px;
        display: block;
        position: absolute;
        z-index: 9999;
        float: left;
        top: 117px;
        left: 193px;
    }
}

@media only screen and (orientation: portrait) and (min-height: 640px) {

    #palestra-evento  .container-palestra {
        height: 640px;
        width: 360px;
    }

    .tela-palestra{     
        width: 192px;
        height: 94px;
        display: block;
        position: absolute;
        z-index: 9999;
        float: left;
        top: 283px;
        left: 85px;
    }
}




// ---------- 960px
@media only screen and (orientation: landscape) and (min-width: 960px) {

    #palestra-evento  .container-palestra {
        width: 960px;
        height: 540px;
    }

    .tela-palestra{     
        width: 387px;
        height: 191px;
        display: block;
        position: absolute;
        z-index: 9999;
        float: left;
        top: 176px;
        left: 289px;
    }
    .hour {
        font-size: 16px;
    }
}
@media only screen and (orientation: portrait) and (min-height: 960px) {

    #palestra-evento  .container-palestra {
        height: 960px;
        width: 540px;
    }

    .tela-palestra{     
        width: 286px;
        height: 140px;
        display: block;
        position: absolute;
        z-index: 9999;
        float: left;
        top: 425px;
        left: 128px;
    }
    .hour {
        font-size: 16px;
    }
}




// ---------- 1024px
@media only screen and (orientation: landscape) and (min-width: 1024px) { 

    #palestra-evento  .container-palestra {
        width: 1024px;
        height: 576px;
    }

    .tela-palestra {     
        width: 412px;
        height: 204px;
        display: block;
        position: absolute;
        z-index: 9999;
        float: left;
        top: 187px;
        left: 308px;
    }
}
@media only screen and (orientation: portrait) and (min-height: 1024px) { 

    #palestra-evento  .container-palestra {
        height: 1024px;
        width: 576px;
    }

    .tela-palestra {     
        width: 308px;
        height: 153px;
        display: block;
        position: absolute;
        z-index: 9999;
        float: left;
        top: 452px;
        left: 135px;
    }
}




// ---------- 1280px
@media only screen and (orientation: landscape) and (min-width: 1280px) { 

    #palestra-evento  .container-palestra{
        width: 1280px;
        height: 720px;
    }

    .tela-palestra {     
        width: 516px;
        height: 256px;
        display: block;
        position: absolute;
        z-index: 9999;
        float: left;
        top: 234px;
        left: 384px;
    }
}

@media only screen and (orientation: portrait) and (min-height: 1280px) { 

    #palestra-evento  .container-palestra{
        height: 1280px;
        width: 720px;
    }

    .tela-palestra {     
        width: 385px;
        height: 191px;
        display: block;
        position: absolute;
        z-index: 9999;
        float: left;
        top: 565px;
        left: 169px;
    }
}




// ---------- 1600px
@media only screen and (orientation: landscape) and (min-width: 1600px) {

    #palestra-evento  .container-palestra{
        width: 1600px;
        height: 900px;
    }

    .tela-palestra {     
        width: 645px;
        height: 320px;
        display: block;
        position: absolute;
        z-index: 9999;
        float: left;
        top: 293px;
        left: 480px;
    }
}
@media only screen and (orientation: portrait) and (min-height: 1600px) {

    #palestra-evento  .container-palestra{
        height: 1600px;
        width: 900px;
    }

    .tela-palestra {     
        width: 480px;
        height: 238px;
        display: block;
        position: absolute;
        z-index: 9999;
        float: left;
        top: 706px;
        left: 212px;
    }
}





// ---------- 1920px
@media only screen and (orientation: landscape) and (min-width: 1920px) {

    #palestra-evento  .container-palestra {
        width: 1920px;
        height: 1080px;
    }

    .tela-palestra {     
        width: 774px;
        height: 385px;
        display: block;
        position: absolute;
        z-index: 9999;
        float: left;
        top: 350px;
        left: 576px;
    }
}
@media only screen and (orientation: portrait) and (min-height: 1920px) {

    #palestra-evento  .container-palestra {
        height: 1920px;
        width: 1080px;
    }

    .tela-palestra {     
        width: 574px;
        height: 283px;
        display: block;
        position: absolute;
        z-index: 9999;
        float: left;
        top: 849px;
        left: 256px;
    }
}

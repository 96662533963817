.title-table {
    color: #821b00;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 24px;
    > .MuiSvgIcon-root {
        margin-right: 8px
    }
}

.table-header {
    background-color: #ccc;
}
.root {
    width: 100%;
}
.heading {
    font-size: 16px;
    flex-basis: 15%;
    flex-shrink: 0;
}
.secondaryHeading {
    font-size: 10px;
    line-height: 22px;
    color: #3e3e3e;
}

.MuiButtonBase-root{
    .MuiAccordionSummary-content {
        margin: 18px 0
    }
    &.AccordionSelected {
        background-color: #a34100;
        color: #ffffff;
        .secondaryHeading {
            color: #ffffff;
        }
    }
}

.overlay-button {
    width: auto;
}

.info-icon {
    position: absolute;
    top: 0;
    right: 0;
}

.only-active-switch .MuiSwitch-switchBase{
    color: #a34100;
    &.Mui-checked {
      color: #a34100,
    }
    &.Mui-checked + .MuiSwitch-track {
        background-color: #000;
    }
}

.video-switch {
    display: flex;
    justify-content: center;
    align-items: center;
}
.input-label {
    margin-bottom: 8px
}

.input {
    -webkit-appearance: none;
    background-color: transparent;
    border-width: 0;
    border-bottom-width: 1px;
    width: calc(100% - 16px);
    padding: 5px 8px;
    line-height: 22px;
    margin-bottom: 8px;
    &:focus {
        border-color: blue;
        outline: none;
    }
}


.MuiFormControl-root.hfm-input {
    background: #fff;
    // border-radius: 53px;
    padding: 8px;
    .MuiInputLabel-root {
        padding-top: 16px;
        padding-left: 16px;
    }
}
.loader {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}


.App-logo {
  pointer-events: none;
}

.App-logo-pb {
  position: absolute;
  width: 180px;
  height: 37px;
  top: 0;
  left: 0;
  overflow: hidden;
  transform-origin: bottom;
  .logo-pb {
    width: 180px;
    height: 37px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo-pb {
    animation: App-logo-pb infinite 1s linear;
  }
}

@keyframes App-logo-pb {
  0% {
    height: 37px;
  }
  50% {
    height: 0;
  }
  100% {
    height: 37px;
  }
}
#hall-help-desk  {
    background-color: #000;
    height: 900px;
    width: 1600px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    .container-hall-stands{
        background-repeat: no-repeat;
        background-size: auto 100%;
        width: 1600px;
        position: relative;
        height: 900px;
        margin: 0 auto;
        overflow: hidden;
        a {
            display: flex;
            height: 100%;
        }
    }
    .fade-in {
        -webkit-animation: fadein 1s;
        -moz-animation: fadein 1s;
        animation: fadein 1s;
        opacity: 1;
    }
    @keyframes fadein {
        0% { opacity: 1;  }
        50%   { opacity: 0; }
        100%   { opacity: 1; }
    }
    @-moz-keyframes fadein {
        0% { opacity: 1;  }
        50%   { opacity: 0; }
        100%   { opacity: 1; }
    }
    @-webkit-keyframes fadein {
        0% { opacity: 1;  }
        50%   { opacity: 0; }
        100%   { opacity: 1; }
    }
    .tv {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        cursor: pointer;
        display: block;
        position: absolute;
        z-index: 999;
        float: left;
    }
    .left-tv {
        width: 160px;
        height: 180px;
        top: 555px;
        left: 525px;
    }
    .middle-tv {
        width: 160px;
        height: 180px;
        top: 562px;
        left: 712px;
    }
    .right-tv {
        width: 160px;
        height: 180px;
        top: 542px;
        left: 900px;
    }
}
.logged-box {
    padding: 16px;
    .username {
        font-size: 18px;
        font-family: 'Open Sans';
        font-weight: 600;
    }
    .email {
        font-size: 12px;
    }

    .logout-link {
        display: inline-block;
        margin-left: 4px;
    }
}
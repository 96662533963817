.container {
    padding: 8px;
}

.row {
    margin-left: -8px;
    margin-right: -8px;
}
.full-height {
    min-height: 100vh;
}

.full-width {
    width: 100%;
}
.margin-8 {
    margin: 8px;
}

.media-container {
    display: flex;
    flex-direction: column;
}

.button-wrapper {
    margin-top: 16px;
    padding-top: 24px;
    border-top: 1px solid #e3e3e3;
}
#hall-room-per-date  {
    background-color: #000;
    height: 900px;
    width: 1600px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    .container-room-per-date{
        background-repeat: no-repeat;
        background-size: auto 100%;
        width: 1600px;
        position: relative;
        height: 900px;
        margin: 0 auto;
        overflow: hidden;
        a {
            display: flex;
            height: 100%;
        }
    }
    .date {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        cursor: pointer;
        display: block;
        position: absolute;
        z-index: 999;
        float: left;
    }
    .date-0 {
        width: 194px;
        height: 382px;
        top: 282px;
        left: 386px;
    }
    .date-1 {
        width: 194px;
        height: 382px;
        top: 282px;
        left: 702px;
    }
    .date-2 {
        width: 194px;
        height: 382px;
        top: 282px;
        left: 1016px;
    }
}
#modal.modal-room-per-date {
    .modal-title {
        font-size: 24px;
        text-align: center;
        margin-bottom: 24px;
    }
    .link-video {
        text-align: center;
        width: 14%;
        a {
            margin: 8px;
            svg {
                margin-right: 4px;
            }
        }
    }
    .empty-list {
        min-height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        svg {
            margin-bottom: 16px;
            font-size: 100px;
        }
    }
    .list-item {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #666;
        &.header {
            p[class^="item-"] {
                font-weight: normal;
                color: #F1654C;
            }
        }
    }
    p[class^="item-"] {
        padding: 16px;
        width: 43%;
        &.item-title {
            padding-left: 8px;
            font-weight: bold;
        }
    }
}
.container-login {
    display: flex;
    justify-content: center;
    align-items: center;
    .btn-logar { 
        padding: 7px 30px;
        width: 100%;
        margin-right: 0;
        background: red; border: 1px solid red; color: #fff; border-radius: 5px; transition: 0.3s;
        &.Mui-disabled {
            color: #fff;
        }
    }
    .btn-logar:hover  {border-color: black; background-color: red; }
    .btn-voltar { padding: 7px 30px; width: 48%; background: transparent;
    border: 1px solid black;
    text-align: center;
    color: black; border-radius: 5px; transition: 0.3s;}
    .btn-voltar:hover {
        background: black;
        color: #FFF;
    }
}

.container-login .inner-container {
    padding: 25px;
    background: #FFF;
    width: 25%;
    border-radius: 5px;
    &.-soft {
        min-width: 300px;
    }
}

.container-header {
    margin-bottom: 24px;
    text-align: center;
    padding: 24px 0;
}

.form-contato { width: 100%; position: relative; display: block; float: left; margin-bottom: 5px; padding: 10px; background: #fff; border-radius: 6px; }

.container-login form input { border: 2px solid red; background: black; color: #fff; }

.form-inputs {
    display: flex;
    flex-direction: column;
}
.form-link {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
    a {
        font-size: 14px;
        line-height: 19px;
        display: inline-block;
        &:hover {
            text-decoration: underline !important
        }
    }
}

.resend-link {
    cursor: pointer;
    text-decoration: underline !important;
}
.form-buttons {
    display: flex;
    justify-content: space-between;
    &.-center {
        justify-content: center;
    }
}

.MuiAlert-root.MuiAlert-standardError {
    margin-bottom: 16px;
}

.pass-input-box {
    position: relative;
    .info-icon {
        position: absolute;
        right: 0;
        top: 1px;
        color: #FFF;
    }
}
.GlobalLoader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  .overlay {
    position: absolute;
    opacity: .5;
    width: 100%;
    height: 100%;
    background-color: #333;
  }
}
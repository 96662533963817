.price {
    display: inline-block;
    font-size: 18px;
    &.-through {
        font-size: 11px;
        text-decoration: line-through
    }
}

.discount {
    font-size: 18px;
}

.category-field {
    width: 100%
}

.form-image {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 16
}

.table-row {
    & > * {
      border-bottom: 'unset'
    }
}

.collapsable-image {
    justify-content: center;
    align-items: center;
    display: flex;
}

.form-container {
    margin-bottom: 16px
}
.form-image-buttom {
    justify-content: center;
    align-items: center;
    display: flex;
    > div {
        display: flex;
        align-items: center;
    }
}

.type-sponsor-field {
    width: 100%
}

.absolute-fab.MuiFab-root {
    position: absolute;
    bottom: -24px;
    right: 24px;
}

.event-page .MuiPaper-root{
    div .MuiTable-root {
        display: none;
    }
}
.MuiGridListTile-tile.raisable-tile {
    overflow-y: visible
}

.MuiPaper-elevation2.hidden-table {
    box-shadow: none;
}

.MuiFormControl-root.MTableToolbar-searchField-35 {
    min-width: 0;
    width: 50%;
}
.rcw-messages-container .loader {
  display: none;
}

.rcw-conversation-container > .rcw-header {
  background-color: #F1654C;
}

.rcw-messages-container > .rcw-message > .rcw-client > .rcw-message-text {
  background-color: #ffad7d;
}

.rcw-launcher {
  background-color: #F1654C;
}
